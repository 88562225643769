import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const CtaContainer = ({ align, variant, className, children }) => {
    const classes = [
        'c-cta-container',
        ...(align ? [`c-cta-container--${align}`] : []),
        ...(variant ? [`c-cta-container--${variant}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return <div className={concatenatedClasses}>{children}</div>
}

CtaContainer.propTypes = {
    /**
     * Specify the horizontal alignment
     */
    align: PropTypes.oneOf(['centered', 'right']),
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['compact', 'reduced', 'large', 'column']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

CtaContainer.defaultProps = {
    children: '<p>Buttons go here</p>'
}

export default CtaContainer
